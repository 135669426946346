// stylelint-disable declaration-no-important

@each $color, $value in $theme-colors {
  @include bg-variant(".bg-#{$color}", $value);
}

@if $enable-gradients {
  @each $color, $value in $theme-colors {
    @include bg-gradient-variant(".bg-gradient-#{$color}", $value);
  }
}

.bg-white {
  background-color: $white !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-blue {
  background-color: #1160aa !important;
}

.bg-light-blue {
  background-color: #1f95c8 !important;
}

.bg-dark-yellow {
  background-color: #e78e4a !important;
}

.bg-red {
  background-color: #e23744 !important;
}

.bg-dark-red {
  background-color: #D96459 !important;
}

.bg-gray {
  background-color: #ecf0f1 !important;
}