/*=========== Map page Style =========*/
.map-container {
  width: 100%;
  height: 100%;

  .sido-marker {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 38px;
    margin-top: -18px;
    padding: 0 10px 0 4px;
    border-radius: 19px;
    background-color: #364f6b;

    .count {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 30px;
      min-width: 30px;
      margin-right: 5px;
      font-size: 14px;
      color: #364f6b;
      background-color: white;
      border-radius: 15px;
    }

    .region {
      flex: 1;
      font-size: 14px;
      color: white;
    }
  }

  .sigungu-marker {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 26px;
    margin-top: -13px;
    padding: 0 10px 0 0;
    border: 1px solid #326cf9;
    border-radius: 19px;
    background-color: white;

    .count {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 26px;
      min-width: 30px;
      margin-right: 5px;
      font-size: 13px;
      color: white;
      background-color: #326cf9;
      border-radius: 13px;
    }

    .region {
      flex: 1;
      font-size: 13px;
      color: #326cf9;
    }
  }

  .device-marker {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -35px;

    & > div {
      padding: 2px;
      font-size: 12px;
      background-color: white;
      border-radius: 5px;
      border: 1px solid #2C3E50;
    }

    i {
      font-size: 30px;
    }
  }
}