/*=========== Admin Style =========*/
.page-content {
  width: 100%;
  max-width: 1200px;

  .page-header {
    margin: 0 0 15px;
    padding: 2px 5px;
    font-size: 23px;
    font-family: 's-core_dream5', sans-serif;
    border-bottom: 1px solid #dddddd;
  }

  .page-table-container {
    .page-table-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 3px;
      font-size: 13px;
      border-bottom: 1px solid #999999;
    }
  }
}

table.main-table {
  width: 100%;
  margin: 10px 0;

  td, th {
    padding: 7px;
    text-align: center;
    font-size: 13px !important;
    color: #333333 !important;
  }

  thead {
    padding: 0 7px;
    background-color: #dddddd;
  }

  tbody tr {
    background-color: white;
    border-bottom: 1px solid #dddddd;
  }
}

.search-bar {
  flex: 1;
  position: relative;

  i {
    position: absolute;
    left: 10px;
    top: 7px;
    color: #dddddd;
    font-size: 21px;
  }

  input {
    padding-left: 33px;
  }

  button {
    color: white;
    background-color: #d96459;
  }
}

.ql-editor {
  min-height: 200px;
}

/*===== issue page  =====*/
.issue-img {
  position: relative;
  img {
    width: auto;
    height: 150px;
    object-fit: contain;
  }
  i {
    position: absolute;
    top: 7px;
    right: 7px;
    font-size: 25px;
    color: red;
    cursor: pointer;
  }
}

/*===== bannder page  =====*/
.banner-list {
  .banner-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 0;

    & > div:nth-child(1) {
      width: 100px;
    }
    & > div:nth-child(2) {
      width: 75px;
    }
    & > div:nth-child(3), & > div:nth-child(4) {
      flex: 1;
    }
    & > div:nth-child(5) {
      width: 200px;
    }
    & > div:nth-child(6) {
      width: 300px;
    }
  }

  .banner-content {

  }

  .banner-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 0;
    margin: 10px 0;
    background-color: white;
    & > div:nth-child(1) {
      width: 100px;
    }
    & > div:nth-child(2) {
      width: 75px;
    }
    & > div:nth-child(3), & > div:nth-child(4) {
      flex: 1;
      img {
        width: auto;
        height: 80px;
      }
    }
    & > div:nth-child(5) {
      width: 200px;
    }
    & > div:nth-child(6) {
      width: 300px;
    }
  }
}

/*===== link page  =====*/
.link-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
  padding: 20px 30px;
  background-color: white;
  & > span {
    width: 250px;
  }
  & > input {
    flex: 1;
    margin-right: 70px;
  }
}

/*===== user page  =====*/
td.user-status {
  i.zmdi {
    cursor: pointer;

    &.zmdi-check-circle {
      color: #00d014 !important;
    }

    &.zmdi-close-circle {
      color: #fd1d1d !important;
    }
  }
}
