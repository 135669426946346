@font-face {
    font-family: 's-core_dream1';
    src: url('scdream1.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 's-core_dream2';
    src: url('scdream2.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 's-core_dream3';
    src: url('scdream3.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 's-core_dream4';
    src: url('scdream4.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 's-core_dream5';
    src: url('scdream5.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 's-core_dream6';
    src: url('scdream6.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 's-core_dream7';
    src: url('scdream7.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 's-core_dream8';
    src: url('scdream8.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 's-core_dream9';
    src: url('scdream9.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}