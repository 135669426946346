//--- Font size ---//
.font-lg {
  font-size: $font-size-lg;
}
.font-md {
  font-size: ($font-size-sm + 0.125rem);
}
.font-sm {
  font-size: $font-size-sm !important;
}
.font-xs {
  font-size: 0.75rem !important;
  line-height:1.25rem;
}
.font-2x {
  font-size: 2rem;
}
.font-3x {
  font-size: 3rem;
}
.font-4x {
  font-size: 4rem !important;
}
.font-5x {
  font-size: 5rem;
}
.font-xl {
  font-size: 2.625rem; //42px;
}
.fs-11 {
  font-size: 11px;
}
.fs-12 {
  font-size: 12px;
}
.fs-13 {
  font-size: 13px;
}
.fs-14 {
  font-size: 14px;
}
.fs-15 {
  font-size: 15px;
}
.fs-16 {
  font-size: 16px;
}
.fs-19 {
  font-size: 19px;
}
.fs-23 {
  font-size: 23px;
}
.fs-50 {
  font-size: 50px;
}
//Font weight
.fw-light{
   font-weight:300 !important;
}
.fw-normal {
  font-weight: $fw-normal !important;
}
.fw-semi-bold {
  font-weight: $fw-semi-bold !important;
}
.fw-bold {
  font-weight: $fw-bold !important;
}
// Font Text
.text-black {
  color: black;
}
.text-pink {
	color: $pink !important;
}
.text-indigo {
	color: $indigo !important;
}
.text-teal {
	color: $teal !important;
}
.text-purple {
	color: $purple !important;
}
.text-yellow {
	color: $orange !important;
}
.text-gray {
  color: #666666;
}
.text-base {
  color: #333333;
}
.text-blue {
  color: #1160AA;
}
.text-light-blue {
  color: #1F95C8
}
.text-underline {
  text-decoration: underline;
}
.cursor-pointer {
  cursor: pointer;
}

.facebook{
  color:$facebook;
}
.twitter{
  color:$twitter;
}
.linkedin{
  color:$linkedin;
}
.google{
  color:$google;
}

.ff-dream1 {
  font-family: 's-core_dream1', sans-serif;
}
.ff-dream2 {
  font-family: 's-core_dream2', sans-serif;
}
.ff-dream3 {
  font-family: 's-core_dream3', sans-serif;
}
.ff-dream4 {
  font-family: 's-core_dream4', sans-serif;
}
.ff-dream5 {
  font-family: 's-core_dream5', sans-serif;
}
.ff-dream6 {
  font-family: 's-core_dream6', sans-serif;
}
.ff-dream7 {
  font-family: 's-core_dream7', sans-serif;
}
.ff-dream8 {
  font-family: 's-core_dream8', sans-serif;
}
.ff-dream9 {
  font-family: 's-core_dream9', sans-serif;
}