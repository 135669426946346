/*=========== Session Style =========*/
.login-container {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;

  .login-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: #324c6e;

    .form-container {
      display: flex;
      flex: 1;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .form-content {
        display: flex;
        flex-direction: column;
        width: 410px;
        padding: 30px;
        background-color: white;
        border-radius: 20px;

        .login-logo {
          width: 210px;
          height: auto;
          margin: auto;
        }

        .login-title {
          margin: 3px 0 10px;
          font-size: 13px;
          color: #666666;
          text-align: center;
        }

        .login-input {
          position: relative;
          margin-bottom: 15px;
          input {
            font-size: 13px;
            padding: 10px 10px 10px 40px;
          }
          i {
            position: absolute;
            top: 9px;
            left: 12px;
            font-size: 22px;
            color: #34495E;
          }
        }

        .login-btn {
          margin-top: 27px;
          padding: 10px;
          font-size: 14px;
          color: white;
          background-color: #1160aa;
        }
      }
    }

    .login-footer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 10px 30px 30px;
      .login-small-logo {
        width: 30px;
      }
    }
  }
}